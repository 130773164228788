"use client";

import useSizes from "@/app/[locale]/(static)/product/[product]/hooks/useSizes";
import { convertProductCardToCartItem } from "@/lib/centra/convertToCartItem/convertToCartItem";
import type { ProductCard as PC } from "@/lib/centra/formatters/formatProductCards/formatProductCard";
import useLocation from "@/lib/hooks/useLocation";
import { useCart } from "@/lib/hooks/useSelection";
import {
  convertCardToGTagItem,
  eventAddToCart,
} from "@/lib/utils/Analytics/Functions";
import { useTranslations } from "next-intl";
import { Suspense } from "react";
import { Button } from "..";

const ProductCardActions = ({ product }: { product: PC }) => {
  const t = useTranslations();
  const sizes = product?.description?.size;
  const newSizes = useSizes(sizes);
  const cart = useCart();
  const { currency } = useLocation();
  const addToCart = () => {
    if (!newSizes?.selectedSize) return false;
    if (newSizes?.selectedSize?.itemId) {
      cart.addItem.mutate({
        itemId: newSizes?.selectedSize?.itemId,
        cartItem: convertProductCardToCartItem(product, sizes?.[0]),
      });
      try {
        eventAddToCart({
          currency: currency || "",
          value: product?.description?.price?.[0]?.priceAsNumber,
          items: [
            convertCardToGTagItem(
              product,
              product?.description?.price?.[0]?.priceAsNumber,
            ),
          ],
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <Button onClick={addToCart} full>
      {t("components.cartItem.upSell.addToCart")}
    </Button>
  );
};

export const SuspendedProductCardActions = ({ product }: { product: PC }) => {
  const t = useTranslations();
  return (
    <Suspense
      fallback={
        <Button full>{t("components.cartItem.upSell.addToCart")}</Button>
      }
    >
      <ProductCardActions product={product} />
    </Suspense>
  );
};

export default ProductCardActions;
