import type { CentraVoucher } from "@frend-digital/centra/schemas/selection/utilitySchemas";

import type { PDPProduct } from "@/app/[locale]/(static)/product/[product]/page";

declare global {
  interface Window {
    dataLayer: any;
  }
}

export const pageview = (url: string) => {
  window?.dataLayer?.push({
    config: `${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}`,
    page_path: url,
  });
};

interface GTagEvent {
  action: string;
  category: string;
  label: string;
  value: number;
}

export const event = ({ action, category, label, value }: GTagEvent) => {
  window?.dataLayer?.push({
    event: action,
    event_category: category,
    event_label: label,
    revenue: value,
  });
};

export const generateGTagItem = (
  item: PDPProduct,
  price?: number,
  quantity: number = 1,
) => {
  const item_id = item?.uri || "";
  const item_name = item?.specs?.name || item?.name || "";
  const item_category = item?.breadcrumbs?.[0]?.name || "";
  const item_variant = item?.variantName || "";
  const item_price = price || NaN;
  const item_quantity = quantity;

  return {
    item_id,
    item_name,
    item_category,
    item_variant,
    price: item_price,
    quantity: item_quantity,
  };
};

export const convertCardToGTagItem = (item: any, value: any) => {
  const item_id = item?.core?.slug || "";
  const item_name = item?.description?.name || "";
  const item_category =
    item?.description?.cannonicalCategory || item?.category || "";
  const item_price = value;
  const item_quantity = 1;

  return {
    item_id,
    item_name,
    item_category,
    price: item_price,
    quantity: item_quantity,
  };
};

export const convertCartToGTagItem = (item: any) => {
  const item_id = item?.uri || "";
  const item_name = item?.name || "";
  const item_category = item?.categoryName?.[0] || item?.category || "";
  const item_variant = item?.variant || "";
  const item_price = item.priceAsNumber || NaN;
  const item_quantity = item.quantity;

  return {
    item_id,
    item_name,
    item_category,
    item_variant,
    price: item_price,
    quantity: item_quantity,
  };
};

export interface GTagItem {
  item_id: string; // SKU
  item_name: string; // Product Name
  discount?: number;
  item_category: string; // Top Category /women
  item_category2?: string; // Sub Category /women/!dresses!
  item_category3?: string;
  item_variant: string; // Variant name
  price: number; // Price
  quantity?: number; // How many
}
interface EventCartProps {
  currency: string;
  value?: number;
  items: any[];
}

export const eventAddToCart = ({ currency, value, items }: EventCartProps) => {
  const data = {
    currencyCode: currency,
    currency: currency,
    revenue: value,
    value: value,
    items: items,
  };
  window?.dataLayer?.push({
    event: "add_to_cart",
    ecommerce: data,
  });
};

export const eventRemoveFromCart = ({
  currency,
  value,
  items,
}: EventCartProps) => {
  const data = {
    currencyCode: currency,
    currency: currency,
    revenue: value,
    value: value,
    items: items,
  };
  window?.dataLayer?.push({
    event: "remove_from_cart",
    ecommerce: data,
  });
};

export const eventProductDetail = ({ currency, items }: EventCartProps) => {
  const data = {
    currencyCode: currency,
    currency: currency,
    items,
  };
  window?.dataLayer?.push({
    event: "view_item",
    ecommerce: data,
  });
};

export const eventProductList = ({ currency, id, name }: any) => {
  const data = {
    currencyCode: currency,
    currency: currency,
    item_list_id: id,
    item_list_name: name,
  };
  window?.dataLayer?.push({
    event: "view_item_list",
    ecommerce: data,
  });
};

export const eventProductListSelect = ({ items, id, name }: any) => {
  const data = {
    item_list_id: id,
    item_list_name: name,
    items,
  };
  window?.dataLayer?.push({
    event: "select_item",
    ecommerce: data,
  });
};

export const eventShippingInfo = ({
  currency,
  items,
  value,
}: EventCartProps) => {
  const data = {
    currencyCode: currency,
    currency: currency,
    revenue: value,
    value: value,
    items,
  };
  window?.dataLayer?.push({
    event: "add_shipping_info",
    ecommerce: data,
  });
};

export const eventPaymentInfo = ({
  currency,
  items,
  value,
}: EventCartProps) => {
  const data = {
    currencyCode: currency,
    currency: currency,
    revenue: value,
    value: value,
    items,
  };
  window?.dataLayer?.push({
    event: "add_payment_info",
    ecommerce: data,
  });
};

export const eventViewCart = ({ currency, value, items }: EventCartProps) => {
  const data = {
    currencyCode: currency,
    currency: currency,
    revenue: value,
    value: value,
    items: items,
  };
  window?.dataLayer?.push({
    event: "view_cart",
    ecommerce: data,
  });
};

export const eventBeginCheckout = ({
  currency,
  value,
  items,
}: EventCartProps) => {
  const data = {
    currencyCode: currency,
    currency: currency,
    revenue: value,
    value: value,
    items: items,
  };
  window?.dataLayer?.push({
    event: "begin_checkout",
    ecommerce: data,
  });
};

interface EventPurchaseProps {
  transaction_id: string;
  tax: number;
  shipping: number;
  coupon: string | CentraVoucher;
  currency: string;
  value: number;
  items: GTagItem[];
}

export const eventPurchase = ({
  transaction_id,
  tax,
  shipping,
  coupon,
  currency,
  value,
  items,
}: EventPurchaseProps) => {
  const data = {
    affiliation: "Online Store",
    id: transaction_id,
    tax,
    shipping,
    coupon,
    currencyCode: currency,
    currency: currency,
    revenue: value,
    value: value,
    items: items,
  };
  window?.dataLayer?.push({
    event: "purchase",
    ecommerce: data,
  });
};
